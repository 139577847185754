import React, { useEffect } from "react"
import Layout from "../components/layout"
import LoginContainer from "../containers/login"
import { resetPageProduct } from "../actions/index"
import { useDispatch } from "react-redux"

export const Index = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetPageProduct(null, 50, 40))
  }, [])

  return (
    <Layout>
      <LoginContainer />
    </Layout>
  )
}
export default Index
