import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Image = ({ image, alt }) => (
  <Img
    fluid={image.childImageSharp.fluid}
    style={{
      maxWidth: 200,
      margin: "1rem auto",
    }}
    imgStyle={{ objectFit: "contain" }}
    alt={alt}
  />
)

Image.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.object,
}

const Presentation = () => {
  return (
    <>
      <h2 className="has-text-centered">Des professionnels de l’encadrement à votre service</h2>
      <div className="columns is-multiline has-text-centered">
        <div className="column is-4">
          <svg width="200"><use xlinkHref="#olivier" /></svg>
          <h3>Olivier Devaux</h3>
          <div className="has-text-weight-bold">Directeur commercial</div>
          <div>Responsable de la stratégie d’entreprise</div>
        </div>
        <div className="column is-4">
        <svg width="200"><use xlinkHref="#jean-paul" /></svg>
          <h3>Jean-Paul Donda</h3>
          <div className="has-text-weight-bold">
            Responsable clientèle de proximité
          </div>
          <div>Département sublimation, logistique et internet</div>
          <div />
        </div>
        <div className="column is-4">
        <svg width="200"><use xlinkHref="#franck" /></svg>
          <h3>Franck Maréchal</h3>
          <div className="has-text-weight-bold">
            Responsable technique fabrication et qualité
          </div>
          <div>Recherche et développement de nouveaux concepts</div>
        </div>
      </div>
    </>
  )
}

Presentation.propTypes = {
  data: PropTypes.object
}

export default Presentation