import React from "react"

const Histoire = () => {
  return (
    <>
      <h2 className="has-text-centered"><div style={{ display: 'inline-block', color: '#000', padding: '0 1rem', background: '#ffffffbb', borderRadius: '2px' }}>L’entreprise des <a href="https://www.producadre.fr">professionnels du cadre</a></div></h2>
      <div className="columns is-multiline notre-histoire is-centered">
        <div className="column is-4 hist-item columns is-vcentered">
          
            <svg width="122"><use xlinkHref="#experience" /></svg>
            <div>
              <h3>Près de 25 années d’expérience à votre service </h3>
              <p>La société a été créé en 1996</p>
            </div>
         
        </div>
        <div className="column is-4 hist-item  columns is-vcentered">
        
            <svg width="122"><use xlinkHref="#1000m2" /></svg>
            <div>
              <h3>+ de 1500m² dédiés à l’encadrement</h3>
              <p>Situé à 10 km de Besançon</p>
            </div>
   
        </div>
        <div className="column is-4 hist-item  columns is-vcentered">
         
            <svg width="122"><use xlinkHref="#boutique" /></svg>
            <div>
              <h3><a href="https://www.producadre.fr">Découvrez nos 7 boutiques en ligne</a></h3>
              <p>proposant chacune des spécificités qui sauront répondre à vos exigences</p>
              <p>+ de 1200 moulures en stock</p>
            </div>
          
        </div>
        <div className="column is-4 hist-item  columns is-vcentered">

            <svg width="105"><use xlinkHref="#commande" /></svg>
            <div>
              <h3>1500 à 2000 commandes traitées par mois</h3>
              <p>Expédition dans toute la France et en Europe </p>
            </div>
          
        </div>
        <div className="column is-4 hist-item  columns is-vcentered">
       
            <svg width="122"><use xlinkHref="#equipe" /></svg>
            <div>
              <h3>Des professionnels à votre écoute</h3>
              <p>Une équipe de menuisiers ébénistes et de préparateurs de commande à votre service</p>
            </div>
      
        </div>
        <div className="column is-4 hist-item  columns is-vcentered">

            <svg width="70"><use xlinkHref="#store" /></svg>
            <div>
              <h3>Un magasin d’atelier ouvert à tous</h3>
              <p>Venez rendre visite à Jean-Paul qui saura vous conseiller</p>
            </div>
        
        </div>
      </div>
    </>
  )
}

export default Histoire
