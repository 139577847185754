import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { Link } from "gatsby-plugin-spinner"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import SEO from "./../components/seo"
import About from "./whoarewe"
import Search from "./../components/search"

export const Login = () => {
  const identifiants = useSelector(s => s.identifiants)
  const data = useStaticQuery(graphql`
    query HomeQuery {
      expo: file(relativePath: { eq: "expo-accueil.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Commande de cadres sur mesure pour les grands comptes"
        description="Encadrement de qualité, spécialisé dans la vente aux professionnels : administrations, peintres, expositions, aquarellistes, photographes, agences de communication, imprimeurs, revendeurs..."
        keywords={[
          `France cadre`,
          `Encadreur`,
          `Artiste peintre`,
          `Photographe`,
          `Galeriste`,
          `Imprimeur`,
          `Agence de communication`,
          `Encadrement de qualité`,
          `Professionnels`,
          `Revendeurs`,
          `Inscription`,
          `Connexion`,
          `Auto-entrepreneur`,
        ]}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Accueil",
                item: "https://www.france-cadre.fr",
              },
            ],
          },
        ]}
      />

      <div className="columns is-variable is-8 home-hero">
        <div className="column columns is-vcentered is-centered">
          <div>
            <div style={{ maxWidth: "900px" }}>
              <span className={identifiants === null || identifiants.identifiants === null ? "home-acces mb-4 mt-5" : "home-acces"}>
                Site exclusivement réservé aux professionnels
              </span>
              <div className={identifiants === null || identifiants.identifiants === null ? "columns" : "is-hidden"}>
                <div className="column is-half">
                  <Link to="/acces" style={{ textDecoration: "none" }}>
                    <div className="button home-button-acces ">
                      <span className="anim-arrow mr-3">&rarr;</span> Demandez vos identifiants
                    </div>
                  </Link>
                </div>
                <div className="columns column is-vcentered is-centered">
                  <Link to="/connexion" style={{ textDecoration: "none" }}>
                    <div className="button home-button-acces ">
                      <span className="anim-arrow mr-3">&rarr;</span> Se connecter
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="tags-cloud">
              <div>Aquarellistes</div>
              <div style={{ fontSize: 26 }}>Événementiel</div>
              <div style={{ fontSize: 14 }}>Agences de communication</div>
              <div style={{ fontSize: 32 }}>Expositions</div>
              <div style={{ fontSize: 27 }}>Galeristes</div>
              <div>Encadreurs</div>
              <div style={{ fontSize: 12 }}>Imprimeurs</div>
              <div style={{ fontSize: 35 }}>Administrations</div>
              <div style={{ fontSize: 28 }}>Peintres</div>
              <div style={{ fontSize: 26 }}>Photographes</div>
              <div style={{ fontSize: 19 }}>Architectes d’intérieur</div>
              <div style={{ fontSize: 20 }}>Grands comptes</div>
            </div>
            {identifiants.identifiants !== null ? (
              <div className="is-flex mt-2 is-justify-content-center">
                <a href="catalogue-france-cadre.pdf" target="_blank" className="button is-secondary is-large is-radiusless is-uppercase" style={{ border: 0 }}>
                  &#8681; Télécharger le catalogue
                </a>
              </div>
            ) : null}
          </div>
        </div>
        <div className="column">
          <div style={{ padding: "1rem 2rem" }}>
            <Link
              to="/cadres/"
              style={{
                position: "relative",
                display: "block",
              }}
              className="homeMainLink"
            >
              <Img
                fluid={data.expo.childImageSharp.fluid}
                alt="Les professionnels du cadre"
                style={{
                  maxWidth: 800,
                  marginRight: "2rem",
                  boxShadow: "#e1c1df 0 0 5px",
                }}
              />
              <div className="button is-hidden-mobile">
                <span className="anim-arrow mr-3">&rarr;</span> Voir tous les cadres de la collection
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="columns" style={{ background: "#ddd", marginLeft: -32, marginRight: -32 }}>
        <div className="column is-half is-offset-one-quarter">
          <Search />
        </div>
      </div>

      <About />
    </>
  )
}

Login.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
  loading: PropTypes.object,
  identifiants: PropTypes.object,
}

export default Login
