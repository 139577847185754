import React, { useState } from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-spinner"

import atralgif from "../images/astral.gif"
import cristalgif from "../images/cristal.gif"
import doublodesigngif from "../images/doublo-design.gif"
import doubloMLgif from "../images/doublo-ML.gif"
import doubloCAgif from "../images/doublo-caisse-americaine.gif"

import Presentation from "../components/presentation"
import Histoire from "../components/histoire"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"

export const InfoPage = () => {
  const data = useStaticQuery(graphql`
    query WoAreWeQuery {
      img: file(relativePath: { eq: "les-professionnels-du-cadre.png" }) {
        childImageSharp {
          fixed(width: 400, height: 241, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      astral: file(relativePath: { eq: "astral_gif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cristal: file(relativePath: { eq: "cristal_gif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doublodesign: file(relativePath: { eq: "doublo-design_gif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doubloML: file(relativePath: { eq: "doublo-ML_gif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doubloCA: file(relativePath: { eq: "doublo-caisse-americaine_gif.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      passe: file(relativePath: { eq: "home-passe-partout.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [state, setState] = useState({
    displayAstral: false,
    displayCristal: false,
    displayDoubloD: false,
    displayDoubloCA: false,
    displayDoubloML: false,
  })

  const displayAstral = () => {
    setState({
      displayAstral: !state.displayAstral,
      displayCristal: false,
      displayDoubloD: false,
      displayDoubloCA: false,
      displayDoubloML: false,
    })
  }

  const displayCristal = () => {
    setState({
      displayCristal: !state.displayCristal,
      displayAstral: false,
      displayDoubloD: false,
      displayDoubloCA: false,
      displayDoubloML: false,
    })
  }

  const displayDoubloD = () => {
    setState({
      displayAstral: false,
      displayCristal: false,
      displayDoubloD: !state.displayDoubloD,
      displayDoubloCA: false,
      displayDoubloML: false,
    })
  }

  const displayDoubloCA = () => {
    setState({
      displayAstral: false,
      displayCristal: false,
      displayDoubloD: false,
      displayDoubloCA: !state.displayDoubloCA,
      displayDoubloML: false,
    })
  }

  const displayDoubloML = () => {
    setState({
      displayAstral: false,
      displayCristal: false,
      displayDoubloD: false,
      displayDoubloCA: false,
      displayDoubloML: !state.displayDoubloML,
    })
  }

  return (
    <>
      <section className="section pt-5">
        {/* <div className="has-text-centered mb-6">
          <a href="/catalogue.pdf" className="button is-secondary is-large is-radiusless is-uppercase" style={{ border: 0 }}>
            &#8681; Télécharger le catalogue
          </a>
       </div> */}
        <div className="has-text-centered is-italic is-size-3 has-text-weight-bold">
          <Link to="/cadres/">Nos savoir faire et exclusivités</Link>
        </div>
        <div className="columns is-variable is-8 mt-3">
          <div className="column">
            <div style={{ textAlign: "justify" }}>
              <h2 className="has-text-centered">Le cadre entre 2 verres</h2>
              <p>
                Ce <a href="/cadres/Entre-deux-verres-Entre-2-verres-Naturel-1888">cadre entre 2 verres</a> de conception exclusive vous assurera une{" "}
                <b>mise en valeur très originale</b> de votre document : photo, lithographie, poster, affiche...
              </p>
              <Link to="/cadres/Entre-deux-verres-Entre-2-verres-Naturel-1888">
                {state.displayCristal ? (
                  <img src={cristalgif} alt="Cadre astral" style={{ width: 260, margin: "0 auto", display: "block", paddingBottom: "50px" }} />
                ) : (
                  <Img
                    fluid={data.cristal.childImageSharp.fluid}
                    alt="Cadre cristal"
                    style={{ width: 260, margin: "0 auto", display: "block", paddingTop: "50px" }}
                  />
                )}
              </Link>
              <div className="has-text-centered">
                <button
                  className="button mr-2"
                  onClick={() => {
                    displayCristal()
                  }}
                >
                  <FontAwesomeIcon icon={faPlayCircle} />
                  &nbsp;&nbsp;Vue 360°
                </button>
                <Link to="/cadres/Entre-deux-verres-Entre-2-verres-Naturel-1888" className="button">
                  Découvrir
                </Link>
              </div>
              <p>
                Le montage entre deux verres consiste à <b>glisser votre sujet entre deux plaques de verres plus grandes</b> maintenues par le cadre CRISTAL.
              </p>
              <p>
                La transparence du verre entre votre sujet et le cadre donnera l’impression que le sujet <b>flotte dans l’espace.</b> Effet garanti !
              </p>
              <p>Le montage de votre sujet dans le cadre se fait très facilement grâce au système mis en place par nos soins.</p>
            </div>
          </div>
          <div className="column">
            <h2 className="has-text-centered">Les passe partout</h2>
            <p>
              Tous nos <Link to="/passe-partout">passes partout</Link> sont découpés numériquement dans nos ateliers de Besançon.
            </p>
            <p>Un choix varié qui saura répondre répondre à tous vos besoins (déco, conservation, ...).</p>
            <Link to="/passe-partout">
              <Img fluid={data.passe.childImageSharp.fluid} alt="Passe partout" style={{ maxWidth: 250, margin: "2rem auto" }} />
            </Link>
            <div className="has-text-centered">
              <Link to="/cadres/Entre-deux-verres-Entre-2-verres-Naturel-1888" className="button">
                Découvrir
              </Link>
            </div>
            <p>Un passe-partout est un encadrement en carton posé sur une œuvre d&apos;art ou une reproduction.</p>
            <p>
              La fonction première du passe partout est de <b>créer un espace entre l&apos;œuvre et le verre</b> de l&apos;encadrement de façon à{" "}
              <b>protéger l&apos;œuvre d&apos;un contact direct</b>. Il assure également une <b>fonction décorative</b>.
            </p>
            <p>
              Idéal pour mettre en valeur les <strong>aquarelles, photos, sérigraphies, dessins</strong>, etc.
            </p>
          </div>
          <div className="column">
            <h2 className="has-text-centered">
              <span
                style={{ fontSize: 15, color: "#fff", padding: "0.2rem 0.5rem", marginRight: "0.5rem", position: "relative", top: -8, background: "#d5ca2d" }}
              >
                Nouveau
              </span>{" "}
              Le cadre astral
            </h2>
            <p>
              Ce <Link to="/cadres/Cadre-astral-support-rigide-dibon-etc...-ASTRAL-Noir-2072">cadre nouvelle génération</Link> exclusivement fabriqué dans nos ateliers de Besançon saura
              mettre en valeur vos <strong>sujets sur support rigide</strong> (dibon, sublimation, ...).
            </p>
            <p>A découvrir absolument ce nouveau concept d’encadrement.</p>
            <div>
              <Link to="/cadres/Cadre-astral-support-rigide-dibon-etc...-ASTRAL-Noir-2072">
                {state.displayAstral ? (
                  <img src={atralgif} alt="Cadre astral" style={{ width: 320, margin: "0 auto", display: "block", marginBottom: "50px", marginTop: "50px" }} />
                ) : (
                  <Img
                    fluid={data.astral.childImageSharp.fluid}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginBottom: "50px", marginTop: "50px" }}
                  />
                )}
              </Link>
            </div>
            <div className="has-text-centered">
              <button className="button mr-2" onClick={() => displayAstral()}>
                <FontAwesomeIcon icon={faPlayCircle} />
                &nbsp;&nbsp;Vue 360°
              </button>
              <Link to="/cadres/Cadre-astral-support-rigide-dibon-etc...-ASTRAL-Noir-2072" className="button">
                Découvrir
              </Link>
            </div>
            <p>
              Un design élégant et moderne. Le cadre ASTRAL <b>remplace le passe-partout par du vide, et accentue la mise en valeur de votre sujet</b>.
              Originalité garantie !
            </p>
          </div>
        </div>
        <div className="has-text-centered is-italic is-size-3 mt-6 pt-2 pb-2">
          <Link to="/categorie/Cadre-doublo-design">
            <b>CADRES DOUBLO</b> ou l’art de personnaliser votre encadrement (<b>double cadre</b>).
          </Link>
          <br />
          <small className="is-size-5">De multiples combinaisons qui satisferont les + exigeants en matière de décoration !</small>
        </div>
        <div className="columns is-variable is-8 mt-3">
          <div className="column">
            <h2 style={{ textAlign: "center" }}>Doublo design</h2>
            <p>
              Ce cadre <Link to="/categorie/Cadre-doublo-design">DOUBLO DESIGN</Link> permet de donner beaucoup <b>+ d’éclat au sujet encadré</b>.
            </p>
            <p>
              Idéal pour la mise en valeur de vos <b>aquarelles, photos, sérigraphies, dessins</b>. Pourra aussi convenir pour encadrer des{" "}
              <b>cartons toilés ou des toiles sur châssis</b>, etc.
            </p>
            <div>
              <Link to="/categorie/Cadre-doublo-design">
                {state.displayDoubloD ? (
                  <img
                    src={doublodesigngif}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginTop: "50px", paddingBottom: "50px" }}
                  />
                ) : (
                  <Img
                    fluid={data.doublodesign.childImageSharp.fluid}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginBottom: "50px", marginTop: "50px" }}
                  />
                )}
              </Link>
            </div>
            <div className="has-text-centered">
              <button className="button mr-2" onClick={() => displayDoubloD()}>
                <FontAwesomeIcon icon={faPlayCircle} />
                &nbsp;&nbsp;Vue 360°
              </button>
              <Link to="/categorie/Cadre-doublo-design" className="button">
                Découvrir
              </Link>
            </div>
            <p>
              <b>La couleur du cadre intérieur est proposée dans des finitions multiples</b> : laquée ou satinée ou encore intemporel (or , argent, etc.).
            </p>
          </div>

          <div className="column">
            <h2 style={{ textAlign: "center" }}>Doublo caisse américaine</h2>
            <p>
              Ce cadre <Link to="/categorie/Cadre-doublo-caisse-americaine">DOUBLO CAISSE AMÉRICAINE</Link> permet de donner un effet très{" "}
              <b>design et contemporain</b> au sujet encadré (très apprécié des galeries d’art).
            </p>
            <p>
              Spécialement conçu pour encadrer vos <strong>toiles sur châssis d’épaisseur de 2cm à 4cm</strong>.
            </p>
            <div>
              <Link to="/categorie/Cadre-doublo-caisse-americaine">
                {state.displayDoubloCA ? (
                  <img
                    src={doubloCAgif}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginTop: "50px", paddingBottom: "50px" }}
                  />
                ) : (
                  <Img
                    fluid={data.doubloCA.childImageSharp.fluid}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginBottom: "50px", marginTop: "50px" }}
                  />
                )}
              </Link>
            </div>
            <div className="has-text-centered">
              <button className="button mr-2" onClick={() => displayDoubloCA()}>
                <FontAwesomeIcon icon={faPlayCircle} />
                &nbsp;&nbsp;Vue 360°
              </button>
              <Link to="/categorie/Cadre-doublo-caisse-americaine" className="button">
                Découvrir
              </Link>
            </div>
            <p>La caisse américaine intérieure vous est proposée dans différentes couleurs.</p>
          </div>

          <div className="column">
            <h2 style={{ textAlign: "center" }}>Doublo marie-louise</h2>
            <p>
              Ce cadre <Link to="/categorie/Cadre-doublo-marie-louise">DOUBLO MARIE LOUISE</Link> permet de donner une touche <b>classique et élégante</b> au
              sujet encadré.
            </p>
            <p>
              Spécialement conçu pour encadrer vos <b>toiles sur châssis ou vos cartons toilés</b>, pourra aussi convenir pour encadrer vos photos, aquarelles,
              dessins...
            </p>
            <div>
              <Link to="/categorie/Cadre-doublo-marie-louise">
                {state.displayDoubloML ? (
                  <img
                    src={doubloMLgif}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginTop: "50px", paddingBottom: "50px" }}
                  />
                ) : (
                  <Img
                    fluid={data.doubloML.childImageSharp.fluid}
                    alt="Cadre astral"
                    style={{ width: 320, margin: "0 auto", display: "block", marginBottom: "50px", marginTop: "50px" }}
                  />
                )}
              </Link>
            </div>
            <div className="has-text-centered">
              <button className="button mr-2" onClick={() => displayDoubloML()}>
                <FontAwesomeIcon icon={faPlayCircle} />
                &nbsp;&nbsp;Vue 360°
              </button>
              <Link to="/categorie/Cadre-doublo-marie-louise" className="button">
                Découvrir
              </Link>
            </div>
            <p>
              La couleur de la marie louise intérieure est proposée dans 3 finitions : de couleur crème avec filet or, crème filet argent ou complètement crème.
            </p>
          </div>
        </div>
        <p className="has-text-centered mt-6 pt-6 mb-6">
          <Link to="/cadres/" className="button is-large is-size-2">
            <span className="anim-arrow mr-3">&rarr;</span> Voir tous les cadres de la collection France-Cadre
          </Link>
        </p>
      </section>
      <div className="columns is-variable is-8 home-hero-2">
        <section className="section">
          <Histoire />
        </section>
      </div>
      <section className="section">
        <Presentation />
      </section>
    </>
  )
}

export default InfoPage
